import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PagePrivacy = props => {
  return (
    <Layout {...props}>
      <Seo title='Política de Privacidad' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Política de Privacidad'
          subheader='
          La presente Política de Privacidad ("Política de Privacidad") rige para el tratamiento de los datos personales que usted como usuario ("Usuario") del sitio web, voluntariamente brinda a Pesoplus Argentina S.A. ("Pesoplus"), al navegar por la misma y utilizar los productos, servicios y/o herramientas ("Servicios") dispuestos para el Usuario por Pesoplus a través de su sitio web (el "Sitio"). Esta Política de Privacidad debe ser leída en conjunto con los respectivos términos de uso ("Términos de Uso"). 
          <br><br>
          Los Términos de Uso y la Política de Privacidad establecen los términos y condiciones aplicables al uso del Sitio por los Usuarios y a los Servicios ofrecidos por Pesoplus. En caso de discordancia con cualquier cláusula o condición de los Términos de Uso y de la Política de Privacidad, solicitamos que no utilice los Servicios ofrecidos por Pesoplus en el Sitio. Al aceptar, haciendo click en la solapa "ACEPTO" al final del documento, el Usuario presta su consentimiento voluntario, libre y expreso al tratamiento descrito en la presente Política de Privacidad. 
          <br><br>
          Al acceder al Sitio y utilizar los Servicios, el Usuario manifiesta estar consciente de esta Política de Privacidad que rige su relación con Pesoplus. Pesoplus reitera su compromiso con nuestros Usuarios y su preocupación con la privacidad y la protección de sus datos personales. En caso de dudas sobre esta Política de Privacidad, entre en contacto con nosotros a través de los canales indicados al final de este instrumento. 
          <br><br>
          <strong>I. Recolección de Datos Personales</strong> 
          <ul>
            <li>1.1. En el desempeño de sus Servicios, Pesoplus solicita y obtiene datos personales de sus Usuarios, datos que el Usuario brindará voluntariamente a fin de poder utilizar los Servicios ofrecidos por Pesoplus al usar el Sitio y/o al registrarse y/o al crear una cuenta en él.</li>
            <li>1.2. Los datos personales requeridos a los Usuarios a través del Sitio, serán utilizados con la finalidad descrita en el punto 2.2 de esta Política de Privacidad. Los datos objeto de tratamiento no serán utilizados para finalidades distintas o incompatibles con las mencionadas en el punto 2.2. y que motivaron su obtención. Asimismo, se deja constancia que los datos requeridos a los Usuarios resultan adecuados, pertinentes y no excesivos para la finalidad para la cual se recaban.</li>
            <ul>
              <li>1.2.1. Pesoplus solo requerirá a los Usuarios datos tales como: apellido, nombre, documento de identidad, fecha de nacimiento, dirección de correo electrónico, etc., absteniéndose de solicitar datos sensibles (Art. 7 de la ley N° 25.326). En caso que sea de su interés, el Usuario podrá suministrar a Pesoplus datos personales sensibles, limitado ellos a brindar voluntariamente información relacionada a su embarazo, que serán tratados para las finalidades descritas en la presente Política de Privacidad. El suministro de esta información es siempre voluntario, debiendo el Usuario prestar su consentimiento en forma separada para poder hacer tratamiento de estos datos. Se entiende como dato sensible, aquellos datos personales referidos al origen racial y étnico; opiniones o convicciones política, religiosas, filosóficas o morales; afiliación sindical; e información relacionada a la salud o a la salud o a la vida sexual. Los Usuarios garantizan la veracidad y certeza de los datos personales que ingresen en el Sitio, asumiendo la correspondiente responsabilidad en caso de que los mismos resulten inexactos. Pesoplus, en su condición de destinatario de los datos, no asume ninguna responsabilidad en caso de existir inexactitud en los datos personales ingresados por los Usuarios.</li>
            </ul>
            <li>1.3. En caso que el Usuario desee adquirir productos o Servicios a través del Sitio, Pesoplus deberá recolectar datos adicionales, tales como datos de tarjetas de crédito o débito u otros medios de pagos habilitados en el Sitio, así como la dirección de entrega y datos relacionados, que serán exclusivamente utilizados para fines de realización de pago de los Servicios contratados.</li>
            <li>1.4. Los datos personales informados podrán ser almacenados en servidores localizados en el exterior, teniendo en cuenta los servicios de almacenamiento en nube (cloud) que Pesoplus utiliza, y solamente serán suministrados a terceros en los términos dispuesto por la ley 25.326 y/o mediante orden judicial. Al utilizar los Servicios, el Usuario expresamente presta su consentimiento con que sus datos sean transferidos al exterior, aun a países que no cuenten con niveles adecuados de protección de datos.</li>
            <li>1.5. Los datos recolectados por Pesoplus son almacenados bajo las más rígidas prácticas de seguridad de información en el banco de datos de Pesoplus ("Banco de Datos"). El Banco de Datos es rigurosamente supervisado y protegido, de modo que solo funcionarios habilitados tienen acceso, los cuales están contractualmente obligados a los deberes de secreto y confidencialidad.</li>
            <li>1.6. Al acceder al Sitio, ciertas informaciones sobre el Usuario, como el protocolo de Internet (dirección IP), sistema operativo, tiempo gastado en el Sitio, el identificador individual de un dispositivo móvil y otros datos sobre su configuración, como la resolución de la pantalla, entre otras informaciones, serán almacenadas por Pesoplus. Esos datos son necesarios para poder mostrarle a los Usuarios los contenidos del Sitio y para su correcto funcionamiento. Estos datos pasan por un análisis estadístico, que no está conectado a datos personales.</li>
            <li>1.7. Pesoplus utiliza cookies informáticas para reconocer las preferencias de los Usuarios a fin de poder mejorar el contenido del Sitio en la forma correspondiente. Cada Usuario sigue siendo anónimo. Los datos personales se guardan únicamente cuando son ingresados de manera voluntaria por los Usuarios al acceder a los Servicios prestados por Pesoplus. Pesoplus otorgará a la información obtenida por medio de cookies el mismo tratamiento que le brinda a la información de datos personales. Las finalidades de la recopilación de información a través de cookies son las mismas por las que se recolecta información personal. La información recopilada a través de cookies será transferida a terceros dentro de los mismos límites que la información personal. Pesoplus mantendrá esta información segura de acuerdo a la ley y le otorgará también carácter de confidencial. Los Usuarios cuentan con los mismos derechos sobre la información recogida por medio de cookies que aquellos datos personales otorgados. Los Usuarios se encuentran en la capacidad de deshabilitar la mayoría de cookies que son enviadas a las computadoras y dispositivos móviles por medio del cambio de las configuraciones predispuestas para sus navegadores y sistemas operativos. Deshabilitar las cookies en el navegador puede originar que Pesoplus no pueda ofrecer y/o prestar el mismo servicio y/o contenido personalizado a sus Usuarios.</li>
          </ul>
          <br><br>
          <strong>II. Tratamiento de Datos Personales</strong> 
          <ul>
            <li>2.1. Para la ejecución de los Servicios, Pesoplus realizará, siempre respetando la legislación pertinente, la transferencia de datos personales suministrados por los Usuarios para los colaboradores comerciales y operativos de Pesoplus.</li>
            <ul>
              <li>2.1.1. En el ejercicio de sus actividades, Pesoplus comparte informaciones con terceros, siempre con vistas a posibilitar y a perfeccionar la oferta de sus Servicios y productos, en los siguientes casos:</li>
              <ul>
                <li>(i) <strong>Uso de proveedores o colaboradores:</strong> Pesoplus puede compartir informaciones insertadas por el Usuario con otras personas jurídicas colaboradoras de Pesoplus, por ejemplo, para el suministro de servicios informáticos o de otro tipo.</li>
                <li>(ii) <strong>Nuevos negocios:</strong> En caso de adquisición o fusión de empresas, establecimiento de colaboraciones comerciales, joint ventures y otros negocios.</li>
                <li>(iii) <strong>Cumplimiento de obligación legal:</strong> Pesoplus podrá compartir la información brindada por el Usuario en caso de ser requerido para dar cumplimiento con toda y/o cualquier intimación legal, ya sea judicial o administrativa. En tal caso, Pesoplus notificará al Usuario sobre cuáles informaciones serán compartidas si la ley así lo permite.</li>
              </ul>
            </ul>
            <li>2.2. Pesoplus valoriza la privacidad de sus Usuarios y utiliza los datos personales recolectados con las siguientes finalidades:</li>
            <ul>
              <li><strong>Presentación y venta de productos, así como la ejecución de los diversos servicios y/o herramientas dispuestos para los Usuarios por parte de Pesoplus;</strong></li>
              <li><strong>Atención de las solicitudes, sugerencias y reclamos de los Usuarios;</strong></li>
              <li><strong>Identificación del Usuario que se comunica con Pesoplus por los diversos medios disponibles, a efectos de seguridad y prevención de fraudes;</strong></li>
              <li><strong>Fines administrativos y de gestión;</strong></li>
              <li><strong>Fines contables, fiscales y de facturación, así como la eventual remisión de documentos adicionales para cumplimiento de las obligaciones legales aplicables;</strong></li>
              <li><strong>Fines comerciales, de marketing y/o publicidad, así como acciones comerciales emprendidas por Pesoplus y/o por empresas que formen parte del grupo económico de Pesoplus;</strong></li>
              <li><strong>Personalización de los productos y/o Servicios ofrecidos al Usuario y ajuste del Sitio a sus preferencias personales;</strong></li>
              <li><strong>Realización de análisis estadístico sobre el comportamiento de los Usuarios y la recolección de información que ayude a la mejora y/o creación de nuevos productos, Servicios y/o herramientas.</strong></li>
            </ul>
            <li>2.3. Pesoplus adopta mecanismos para garantizar que la información personal que el Usuario proporciona al Sitio y que recolecta, se mantenga privada y segura. El acceso a la información del Usuario es restringido y controlado. No obstante, Pesoplus no puede garantizar la privacidad y la seguridad de la información proporcionada y/o recolectada cuando se producen eventos fuera del control de Pesoplus. Al proporcionar su información en el Sitio, el Usuario asume este riesgo, y exonera de toda responsabilidad a Pesoplus.</li>
          </ul>
          <br><br>
          <strong>III. Deber de información del Usuario</strong> 
          <ul>
            <li>3.1. Los Usuarios son responsables de la veracidad, exactitud, vigencia y autenticidad de los datos personales ingresados en el Sitio, y se comprometen a mantenerlos actualizados.</li>
            <li>3.2. Pesoplus no será responsable en caso de que los datos de los Usuarios resulten ser inexactos o no verídicos.</li>
          </ul>
          <br><br>
          <strong>IV. Seguridad</strong> 
          <ul>
            <li>4.1. Pesoplus adoptará las medidas de seguridad técnica, organizativa y legalmente requeridas para garantizar la protección de los datos personales de los Usuarios y evitar su alteración, pérdida, tratamiento o acceso no autorizado.</li>
          </ul>
          <br><br>
          <strong>V. Derechos de los Usuarios</strong> 
          <ul>
            <li>5.1. Los Usuarios tienen el derecho de acceder a sus datos personales recolectados y tratados por Pesoplus, conforme la legislación vigente.</li>
            <li>5.2. Asimismo, los Usuarios podrán solicitar la corrección, actualización o eliminación de sus datos personales, en cualquier momento, enviando una solicitud escrita a los canales de contacto disponibles en el Sitio.</li>
          </ul>
          <br><br>
          <strong>VI. Modificaciones a la Política de Privacidad</strong> 
          <ul>
            <li>6.1. Pesoplus se reserva el derecho de modificar la presente Política de Privacidad en cualquier momento, de acuerdo con las necesidades de sus servicios y/o con los cambios en la legislación aplicable.</li>
            <li>6.2. Cualquier cambio en la Política de Privacidad será comunicado a los Usuarios a través del Sitio y/o por otros medios, en la medida de lo posible.</li>
          </ul>
          <br><br>
          <strong>VII. Contacto</strong> 
          <ul>
            <li>7.1. Para cualquier duda, comentario o solicitud relacionada con esta Política de Privacidad, los Usuarios pueden ponerse en contacto con Pesoplus a través de los canales de contacto disponibles en el Sitio.</li>
          </ul>
          <br><br>
          Al hacer clic en "ACEPTO", usted consiente y acepta la presente Política de Privacidad y autoriza el tratamiento de sus datos personales conforme a lo descrito en este documento.
          '
        />
      </Stack>
    </Layout>
  )
}

export default PagePrivacy
